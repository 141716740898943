import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="w3-light-grey">
        <div className="w3-content" style={{maxWidth:'1400px'}}>
          <div className="w3-row-padding">
            <div className="w3-third">
            
              <div className="w3-white w3-text-grey w3-card-4">
                <div className="w3-display-container">
                  <img src="myself.jpg" style={{width:'100%'}} alt="Avatar" />
                  <div className="w3-display-bottomleft w3-container w3-text-black">
                    <h2 className='w3-text-white'>Snehangshu Bhusan Pal</h2>
                    <span className='w3-text-white slogan-under-name'><b>I Teach Business To Computers & Mobiles. <br /> I Lead Teams and make them Passionate</b></span>
                  </div>
                </div>
                <div className="w3-container">
                  <p><i className="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-blue"></i>Fullstack Software Developer & Lead</p>
                  <p><i className="fa fa-home fa-fw w3-margin-right w3-large w3-text-blue"></i>Bangalore, India</p>
                  <p><a href="mailto:snehangshu01@yahoo.co.in" target="_blank"><i className="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-blue"></i>snehangshu01@yahoo.co.in</a></p>
                  <p><a href="https://www.linkedin.com/in/snehangshu-bhusan-pal/" target="_blank"><i className="fa fa-linkedin fa-fw w3-margin-right w3-large w3-text-blue"></i>Find Me on Linkedin</a></p>
                  <hr />

                  <p className="w3-large"><b><i className="fa fa-asterisk fa-fw w3-margin-right w3-text-blue"></i>Skills: Overall 16+ Yrs</b></p>
                  <p className="w3-container" >
                    <span class="tag-cloud">Leadership 6 yr</span>
                    <span class="tag-cloud">Flutter Rust Bridge 1 yr</span>
                    <span class="tag-cloud">Rust 1 yr</span>
                    <span class="tag-cloud">Flutter 1 yr</span>
                    <span class="tag-cloud">AWS Lambda 2 yrs</span>
                    <span class="tag-cloud">AWS SAM Template 2 yr</span>
                    <span class="tag-cloud">AWS Lambda 2 yrs</span>
                    <span class="tag-cloud">Java 14 yrs</span>
                    <span class="tag-cloud">Oracle SQL/PLSQL 14 yrs</span>
                    <span class="tag-cloud">Sprint Boot 5 yrs</span>
                    <span class="tag-cloud">React JS 3 yrs</span>
                    <span class="tag-cloud">React Native 1 yr</span>
                    <span class="tag-cloud">Node JS 1 yr</span>
                    <span class="tag-cloud">AWS EC2/RDS 5 yr</span>
                  </p>
                  <hr />

                  <p className="w3-large w3-text-theme"><b><i className="fa fa-globe fa-fw w3-margin-right w3-text-blue"></i>Languages</b></p>
                  <p>English</p>
                  <div className="w3-light-grey w3-round-xlarge">
                    <div className="w3-round-xlarge w3-blue" style={{height:'24px',width:'100%'}}>
                      <div class="w3-container w3-center w3-round-xlarge w3-blue">Fluent</div>
                    </div>
                  </div>
                  <p>Bengali</p>
                  <div className="w3-light-grey w3-round-xlarge">
                    <div className="w3-round-xlarge w3-blue" style={{height:'24px',width:'100%'}}>
                      <div class="w3-container w3-center w3-round-xlarge w3-blue">Fluent</div>
                    </div>
                  </div>
                  <p>Hindi</p>
                  <div className="w3-light-grey w3-round-xlarge">
                    <div className="w3-round-xlarge w3-blue" style={{height:'24px',width:'50%'}}>
                      <div class="w3-container w3-center w3-round-xlarge w3-blue">Can Speak</div>
                    </div>
                  </div>
                  <br />
                </div>
              </div><br />

            
            </div>

            
            <div className="w3-twothird">
            
              <div className="w3-container w3-card w3-white w3-margin-bottom">
                <h2 className="w3-text-grey w3-padding-16"><i className="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-blue"></i>Work Experience</h2>
                {/* <div class="w3-panel w3-blue w3-card-4 w3-round">
                  <p>My Personal POCs: <a href="/virtualinterviewer/" target="_blank">Virtual Interviewer</a></p>
                </div>  */}
                <div className="w3-container">
                  <h3>Associate Director, Digital Engineering  @ NTT DATA</h3>
                  <h5 className="w3-opacity"><b>Team Lead + Fullstack Developer + Scrum Master + Line Manager</b></h5>
                  <h6 className="w3-text-blue"><i className="fa fa-calendar fa-fw w3-margin-right"></i>February 2022 - <span className="w3-tag w3-blue w3-round">Current</span></h6>
                  <p>
                    <ul>
                      <li>As a Team Lead & Scrum Master, 
                          <ul>
                            <li>I had to Track the progress of the stories assigned to team members</li>
                            <li>Coach them in Technical Difficulties was another thing that I had to do</li>
                            <li>Mediated between Developers and Testers during consfusions and miss communications</li>
                            <li>I had to Chair the Tri-Weekly Scrum Calls within My Team</li>
                            <li>I had to Chair the meetings between Clients and My Team</li>
                            <li>Planning each sprint and alignment within the team members were also a resposibility</li>
                            <li>Interviewing new people and onboarding them was another thing I had to do</li>
                          </ul>
                      </li>
                      <li>As a Developer, 
                          <ul>
                            <li>Developed some stories including both Frontend (React JS) and Backend(Node JS, AWS Lambda)</li>
                            <li>Debugged other developers code in times of need</li>
                            <li>Unit Test during development & Regression Test at the end of Sprint were also my responsibilities</li>
                          </ul>
                      </li>
                      <li>As a Line Manager, 
                          <ul>
                            <li>I had to approve timesheets of the members in my line</li>
                            <li>I had to Track and Manage their leaves</li>
                            <li>Discuss with different Project Managers about feedback and communicate to the members</li>
                          </ul>
                      </li>
                    </ul>
                  </p>
                  <hr/>
                </div>
                <div className="w3-container">
                  <h3>Senior Solution Integrator @ ERICSSON </h3>
                  <h5 className="w3-opacity"><b>Fullstack Developer</b></h5>
                  <h6 className="w3-text-blue"><i className="fa fa-calendar fa-fw w3-margin-right"></i>Nov 2011 - Feb 2022</h6>
                  <p>                    
                      <ul>
                        <li>As a Developer, 
                            <ul>
                              <li>Had the responsibility of customising a Leading Ericsson Product</li>
                              <li>Customized Frontend (JSP), Backend (Java Web application) , SOA APIs (Java) and DB Objects (Oracle SQL/PLSQL) of a Leading Ericsson Product</li>
                              <li>Created several supporting applications using Spring Boot</li>
                              <li>Debugged other developers code in times of need</li>
                              <li>Unit Test during development & Functional Test at the end of Sprint were also my responsibilities</li>
                              <li>Completed 10 yers because I loved the product</li>
                              <li>Was a Critical Resource due to my knowledge and ability</li>
                              <li>Domain: Telecom</li>
                            </ul>
                        </li>
                      </ul>
                  </p>
                  <hr/>
                </div>
                <div className="w3-container">
                  <h3>System Analyst  @ TCS</h3>
                  <h5 className="w3-opacity"><b>Fullstack Developer</b></h5>
                  <h6 className="w3-text-blue"><i className="fa fa-calendar fa-fw w3-margin-right"></i>May 2008 - Nov 2011</h6>
                  <p>                    
                      <ul>
                        <li>As a Developer, 
                            <ul>
                              <li>Customized Frontend (JSP.JSF), Backend (Java Web application) , SOAP APIs (Java) and DB Objects (Oracle SQL/PLSQL)</li>
                              <li>Debugged other developers code in times of need</li>
                              <li>Unit Test during development & Functional Test at the end of Sprint were also my responsibilities</li>
                              <li>Completed 3.5 yers </li>
                              <li>Domain: Telecom</li>
                            </ul>
                        </li>
                      </ul>
                  </p>
                  <br/>
                </div>
              </div>

              <div className="w3-container w3-card w3-white">
                <h2 className="w3-text-grey w3-padding-16"><i className="fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-blue"></i>Education</h2>
                <div className="w3-container">
                  <h5 className="w3-opacity"><b>Bachelor of Technology in Computer Science</b></h5>
                  <h6 className="w3-text-blue"><i className="fa fa-calendar fa-fw w3-margin-right"></i>2003 - 2007</h6>
                  <p>University: West Bengal University of Technology</p>
                  <hr/>
                </div>
                <div className="w3-container">
                  <h5 className="w3-opacity"><b>Class 12/ISC</b></h5>
                  <h6 className="w3-text-blue"><i className="fa fa-calendar fa-fw w3-margin-right"></i>2001 - 2003</h6>
                  <p>School: St. James, School, Kolkata</p>
                  <hr/>
                </div>
                <div className="w3-container">
                  <h5 className="w3-opacity"><b>Till Class 10/ICSE</b></h5>
                  <h6 className="w3-text-blue"><i className="fa fa-calendar fa-fw w3-margin-right"></i>1998 - 2001</h6>
                  <p>School: St. James, School, Kolkata</p>
                  <p>The Foundation of My English was built here.</p>
                  <hr/>
                </div>
              </div>

            
            </div>
            
          
          </div>
          
          
        </div>

        <footer className="w3-container w3-blue w3-center w3-margin-top">
          <p>Find me on social media.</p>
          {/* <i className="fa fa-facebook-official w3-hover-opacity"></i>
          <i className="fa fa-instagram w3-hover-opacity"></i>
          <i className="fa fa-snapchat w3-hover-opacity"></i>
          <i className="fa fa-pinterest-p w3-hover-opacity"></i>
          <i className="fa fa-twitter w3-hover-opacity"></i> */}
          <a href="https://www.linkedin.com/in/snehangshu-bhusan-pal/" target="_blank"><i className="fa fa-linkedin w3-hover-opacity"></i></a>
          <p>Powered by <a href="https://www.w3schools.com/w3css/default.asp" target="_blank">w3.css</a></p>
        </footer>

    </div>
  );
}

export default App;
